<template>
  <section>
    <nav-bar />
    <template v-for="c in children">
      <router-view :key="c" :name="c" />
    </template>
    <!-- <whats-app /> -->
  </section>
</template>

<script>
  import NavBar from '../components/NavBar.vue';
  // import WhatsApp from '../components/Messager.vue';
  export default {
    components: {
      NavBar,
      // WhatsApp,
    },
    beforeMount() {
      if (location.hash == '#portfolio') this.$router.push('/portfolio');
    },
    computed: {
      children() {
        return (this.$route.matched.length) ? Object.keys(this.$route.matched[0].components) : []
      }
    }
  }
</script>